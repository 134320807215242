import styled from "styled-components";
import Slider from "react-slick";
import MediaMin from "../global/MediaMin";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  SectionMain,
} from "./Container";
import { getImageUrl } from "../Provider/Api";

const SliderWrapper = styled.div`
  .slick-slider {
    height: 400px;
    ${MediaMin.md} {
      height: 600px;
    }
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide {
    margin-right: 10px;
  }
  .slick-arrow {
    text-decoration: none;
    z-index: 10;
    font-size: 35px;
  }
  .slick-prev {
    left: 25px;
  }
  .slick-next {
    right: 25px;
  }
  .slick-dots {
    bottom: 25px;
    li {
      button:before {
        font-size: 15px;
        border-radius: 50%;
        color: white;
        opacity: 0.5;
        border: solid 1px white;
      }
      &.slick-active {
        button:before {
          opacity: 1;
        }
      }
    }
  }
`;

const SlickSlide = styled.div`
  height: 400px;
  
  ${MediaMin.md} {
    height: 600px;
  }
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
  position: relative;
`;
const Caption = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 10;
  color: #fff;
  h2,
  p {
    color: #fff;
  }
`;
const SectionImageSlider = ({ items }) => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    
  };

  return (
    <SectionMain bgColor={items[0].backgroundColor}>
      <Container mdMW="70%">
        <SliderWrapper>
          <Slider {...settings}>
            {items.map((item, index) => (
              <SlickSlide key={index} bgImage={getImageUrl(item.csImage)}>
                {item.csHeading && (
                  <Caption>
                    <h2>{item.csHeading}</h2>
                    {item.csSubHeading && <p>{item.csSubHeading}</p>}
                  </Caption>
                )}
              </SlickSlide>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </SectionMain>
  );
};

export default SectionImageSlider;
