import styled from "styled-components";
import MediaMin from "../global/MediaMin";
import ScrollAnimation from "react-animate-on-scroll";
import { getImageUrl } from "../Provider/Api";
import {
  Container,
  SectionMain,
  Heading2,
  Paragraph,
  ButtonLink,
  Button,
} from "./Container";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5);
  padding: 40px;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;
const Content = styled.div`
  width: 100%;
  ${MediaMin.md} {
    width: 50%;
    margin-right: 5%;
  }
`;

const Card = styled.div`
  width: 100%;
  ${MediaMin.md} {
    width: 40%;
  }
`;

const CardImage = styled.img`
  position: relative;

  width: 100%;
`;


const Section2 = ({ item }) => (
  <SectionMain
    bgImage={getImageUrl(item.imgName)}
    bgColor={item.backgroundColor}
  >
    {item.imgName && <Overlay />}
    <Container mdMW="70%">
      {!item.fileSectionPdf && (
        <ScrollAnimation animateIn="fadeInUp">
          
          <Heading2 color={item.imgName && "#fff"}>{item.heading}</Heading2>
          <Paragraph color={item.imgName && "#fff"}>
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </Paragraph>
          {item.label && (
            <ButtonLink>
              <Button to={item.new_url}>{item.label}</Button>
            </ButtonLink>
          )}
        </ScrollAnimation>
      )}
      {item.fileSectionPdf && (
        <Row>
          <Content>
            <ScrollAnimation animateIn="fadeInUp">
              <Heading2 color={item.imgName && "#fff"}>{item.heading}</Heading2>
              <Paragraph color={item.imgName && "#fff"}>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </Paragraph>
              {item.label && (
                <ButtonLink>
                  <Button to={item.new_url}>{item.label}</Button>
                </ButtonLink>
              )}
            </ScrollAnimation>
          </Content>
          {item.fileSectionPdf && (
            <Card>
              <ScrollAnimation animateIn="fadeIn">
                <CardImage src={item.fileSectionPdf} alt="" />
              </ScrollAnimation>
            </Card>
          )}
        </Row>
      )}
    </Container>
  </SectionMain>
);

export default Section2;
