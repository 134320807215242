import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  SectionMain,
  Heading2,
} from "./Container";

const SliderWrapper = styled.div`
  .slick-arrow {
    text-decoration: none;
    z-index: 10;
    font-size: 35px;
  }
  .slick-prev {
    left: 25px;
    &:before { color: #999; }
  }
  .slick-next {
    right: 25px;
    &:before { color: #999; }
  }
  .slick-dots {
    li button:before {
      font-size: 15px;
    }
  }
`;

const SlickSlide = styled.div``;
const Caption = styled.div`
  position: relative;
  width: 70%;
  text-align: center;
  margin-left:15%;
  z-index: 10;
  line-height: 1.7rem;
  h5 {
    font-style: italic;
  }
`;
const SectionContentSlider = ({ items }) => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrow: true,
  };

  return (
    <SectionMain bgColor={items[0].backgroundColor}>
      <Container mdMW="50%">
        {items[0].viewHeading === "1" && (
          <Heading2>{items[0].heading}</Heading2>
        )}
        <SliderWrapper>
          <Slider {...settings}>
            {items.map((item, index) => (
              <SlickSlide key={index}>
                {item.csHeading && (
                  <Caption>
                    {item.csSubHeading && <p>{item.csSubHeading}</p>}
                    {item.csDescription && (
                      <p
                        dangerouslySetInnerHTML={{ __html: item.csDescription }}
                      ></p>
                    )}
                    <h5>{item.csHeading}</h5>
                  </Caption>
                )}
              </SlickSlide>
            ))}
          </Slider>
        </SliderWrapper>
      </Container>
    </SectionMain>
  );
};

export default SectionContentSlider;
