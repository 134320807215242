import React, { createContext, useReducer } from "react";
import AppReducer from "./appReducer";
import { setPageDataAction } from "./appActions";

const initialState = {
  page_data: [],
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setPageData(data) {
    dispatch(setPageDataAction(data));
  }
  return (
    <GlobalContext.Provider
      value={{
        page_data: state.page_data,
        setPageData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
