import { useState, useEffect } from "react";
// import Modal from "react-modal";
import Header from "../global/Header";
import styled from "styled-components";
import NavigationMenus from "../global/NavigationMenus";

import Footer from "../global/Footer";
import "../../css/animate.css";
import LoadSection from "../sections/LoadSection";
import Meta from "../global/Meta";
import { Loading } from "../global/Svg";
import Notice from "../global/Notice";
import MediaMin from "../global/MediaMin";
import { ButtonClick, ScrollTop } from "../global/BookNowButton";

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: white;

  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Col = styled.div`
  &.left {
    width: 100%;
    padding: 20px 10px;
    background-color: #2a3830;
    border-right-width: 1px;
    border-right-color: #fff;
  }
  &.right {
    width: 90%;
    padding: 20px 10px;
    background-color: #2a3830;
  }
  text-align: center;
`;
const Button = styled.a`
  text-decoration: none;
  color: #fff;
  font-weight: 400;

  letter-spacing: 2px;
  display: flex;
    align-items: center;
    justify-content: center;
  &:hover {
    background: #fff;
    color: #000;
  }
`;
const Row = styled.div`
  display: flex;
  margin: 0;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1009;
  ${MediaMin.md} {
    display: none;
  }
`;
const Home = ({ menu, setLoading, pageData }) => {
 
  const [navToggled, setNavToggled] = useState(false);
  //const [homeContent, setHomeContent] = useState({ loading: true, result: {} });

  const [isOpen, setIsOpen] = useState(false);
  const homeContent = {
    loading: false,
    result: pageData['home'],
  };
/* 
  useEffect(() => {
    if (
      homeContent.result &&
      homeContent.result.data &&
      homeContent.result.data.noticeArr &&
      homeContent.result.data.noticeArr.length > 0
    ) {
      setIsOpen(true);
    }
  }, [homeContent]);
*/
  const toggleModal = () => {
    setIsOpen(false);
    const elements = document.getElementsByClassName("ReactModalPortal");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  const handleNavToggle = () => {
    setNavToggled(!navToggled);
    ScrollTop();
  };
  /* function getContent() {
    setHomeContent({ loading: true, result: {} });
    console.log(pageData)
    if (pageData.filter((data) => data.slug === "home").length > 0) {
      setHomeContent({
        loading: false,
        result: pageData.filter((data) => data.slug === "home")[0].data,
      });
    } else {
     getPageInfo("home.json").then((res) => {
        setHomeContent({ loading: false, result: res.data });
        setPageData([...page_data, { slug: "home.json", data: res.data }]);
      });
    }
  }*/
  useEffect(() => {
    //console.log(pageData['home'])
    /*if (pageData) {
      setHomeContent({
        loading: false,
        result: pageData['home'],
      });
    }*/
    
    //window.addEventListener("load", () => {
     // scroll(1);
   // });
  }, [pageData]);

  

  /*const scroll = (flag) => {
    const nav = document.querySelector("#nav img");
   // const homelogo = document.querySelector(".homelogo");
    if (window.location.pathname === "/") {
      if (window.scrollY > 100) {
        nav.style.visibility = "visible";
       // homelogo.style.visibility = "hidden";
      } else {
        nav.style.visibility = "hidden";
       // if (flag===0) { homelogo.style.visibility = "visible"; }
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", () => {
      scroll(0);
    });
    //const nav = document.querySelector("#nav img");
    //nav.style.visibility = "hidden";

    window.addEventListener("load", () => {
       //scroll(1);
    });
  }, []);
  */

  
  return (
    <>
      {homeContent.loading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {homeContent.result && homeContent.result.data && (
        <Meta
          metaTitle={homeContent.result.data.metaTitle}
          metaDesc={homeContent.result.data.metaDesc}
          metaKeywords={homeContent.result.data.metaKeywords}
        />
      )}
      {navToggled ? (
        <NavigationMenus menu={menu} handleNavToggle={handleNavToggle} />
      ) : null}
      <Header logo={true} handleNavToggle={handleNavToggle} />
      {homeContent.result &&
        homeContent.result.data &&
        homeContent.result.data.noticeArr &&
        homeContent.result.data.noticeArr.length > 0 && (
          <Notice
            data={homeContent.result.data.noticeArr[0]}
            isOpen={isOpen}
            toggleModal={toggleModal}
          />
        )}
      {homeContent.result &&
        homeContent.result.sections &&
        homeContent.result.sections.map((item, index) => (
          <LoadSection key={index} pageName="home" item={item} />
        ))}
      {homeContent.result && 
        <Footer />
      }
      {!navToggled && (
        <Row>
          <Col className="left">
          <Button onClick={ButtonClick}>
            BOOK NOW
          </Button>
          </Col>
          {/*
          <Col className="right">
            <Button href="/accommodation/the-lake-house/">
            THE LAKE HOUSE
            </Button>
          </Col>
      */}
        </Row>
      )}
    </>
  );
};

export default Home;
