import { useState } from "react";
import { HomePageBannerMemo } from "./HomePageBanner";
import Section2 from "./Section2";
import Section3 from "./Section3";
import { SectionTileMemo } from "./SectionTile";
import Features from "./Features";
import ImageHeader from "./ImageHeader";
import SectionImageSlider from "./SectionImageSlider";
import SectionContentSlider from "./SectionContentSlider";
import Gallery from "./Gallery";

const LoadSection = ({ pageName, item }) => {
  const [setSelectedImg] = useState(null);
  // console.log(item);
  return (
    <>
      {item[0].sectionLayoutId === "45" && item[0].sectionTypeId === "4" && (
        <HomePageBannerMemo pageName={pageName} item={item[0]} />
      )}
      {item[0].sectionLayoutId === "46" && item[0].sectionTypeId === "1" && (
        <Section2 item={item[0]} />
      )}
      {item[0].sectionLayoutId === "47" && item[0].sectionTypeId === "1" && (
        <Section3 item={item[0]} />
      )}
      {item[0].sectionLayoutId === "49" &&
        item[0].sectionTypeId === "7" &&
        item.map((item1, index1) => (
          <SectionTileMemo item1={item1} key={index1} index1={index1} />
        ))}
      {item[0].sectionLayoutId === "50" && item[0].sectionTypeId === "1" && (
        <Features item={item[0]} />
      )}
      {item[0].sectionLayoutId === "51" && item[0].sectionTypeId === "3" && (
        <ImageHeader item={item[0]} />
      )}
      {item[0].sectionLayoutId === "44" && item[0].sectionTypeId === "6" && (
        <SectionImageSlider items={item} />
      )}
      {item[0].sectionLayoutId === "52" && item[0].sectionTypeId === "5" && (
        <SectionContentSlider items={item} />
      )}
      {item[0].sectionLayoutId === "48" && item[0].sectionTypeId === "4" && (
        <SectionTileMemo item1={item[0]} key={0} index1={0} />
      )}
      {item[0].sectionLayoutId === "61" && item[0].sectionTypeId === "6" && (
        <Gallery setSelectedImg={setSelectedImg} imageUrl={item} />
      )}
    </>
  );
};

export default LoadSection;
