import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ metaTitle, metaDesc, metaKeywords }) => (
  <Helmet>
    {metaTitle && <title>{`${metaTitle}`}</title>}
    {metaDesc && <meta name="description" content={metaDesc} />}
    {metaKeywords && <meta name="keywords" content={metaKeywords} />}
  </Helmet>
);

export default Meta;
