import { getImageUrl } from "../Provider/Api";
import Lightroom from 'react-lightbox-gallery'
import styled from "styled-components";

const GalleryWrapper = styled.div`
  .styles_lightroom__1X2qE {
    position: fixed;
    z-index: 200;
  }
  .styles_thumbpanel__1sa4E {
    z-index: 200;
  }
  .styles_topmenu__oGT_u img, .styles_carouselcontrolprev__Bmyua, .styles_carouselcontrolnext__DoQ9- , .styles_column__2r45X{
    cursor: pointer;
  }

`;

// const Columns = {
//   default: 4,
//   1200: 3,
//   1000: 2,
//   700: 1,
// };

const Gallery = ({ setSelectedImg, imageUrl }) => {
  const images = [];

  imageUrl.map(item => item.csImage ? images.push({src: getImageUrl(item.csImage), desc: item.csHeading}) : '');
 
  var settings = {
    columnCount:{
      default:3,
      mobile:1,
      tab:2
    },
    mode: 'dark'
  }

  return (
    <GalleryWrapper>
      <Lightroom images={images} settings={settings} />
    {/*
    <div
      className="center-masonry"
      style={{ backgroundColor: imageUrl[0].backgroundColor }}
    >
      <Masonry
        breakpointCols={Columns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        style={{ backgroundColor: imageUrl[0].backgroundColor }}
      >
        {imageUrl.map((data, index) => {
          return (
            <div
              key={index}
              data-fancybox="gallery"
              data-src={getImageUrl(data.csImage)}
              data-caption={data.csHeading}
            >
              <img src={getImageUrl(data.csImage)} alt="" />
            </div>
          );
        })}
      </Masonry>
    </div>
      */}
    </GalleryWrapper>
  );
};
export default Gallery;
