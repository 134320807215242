import styled from "styled-components";
import MediaMin from "../global/MediaMin";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, SectionMain, Heading2, Paragraph } from "./Container";
import { getImageUrl } from "../Provider/Api";

const SectionContent = styled.div`
  background: #fff;
  padding: 30px;
  ${MediaMin.md} {
    padding: 50px;
  }
  width: 100%;
  text-align: center;
`;

const ParagraphInd = styled(Paragraph)`
  padding: 0;
  margin: 0;
`;

const Link = styled.a`
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;

  border-color: #2a3830;
  text-decoration: none;
  border: 2px solid #2a3830;
  font-weight: 700;
  display: inline-block;
  margin: 40px 0 20px;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  &:hover {
    background-color: #2a3830;
    color: #fff;
  }
`;

const Section3 = ({ item }) => (
  <SectionMain bgImage={getImageUrl(item.imgName)} >
    <Container mdMW="50">
      <SectionContent>
        <ScrollAnimation animateIn="fadeInUp">
          <Heading2>{item.heading}</Heading2>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp">
          <ParagraphInd>
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </ParagraphInd>
        </ScrollAnimation>
        {item.label && (
          <ScrollAnimation animateIn="fadeInUp">
            <Link href={item.new_url}>{item.label}</Link>
          </ScrollAnimation>
        )}
      </SectionContent>
    </Container>
  </SectionMain>
);
export default Section3;
