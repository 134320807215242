import styled from "styled-components";
import { SectionMain, Container, Heading2 } from "./Container";
import MediaMin from "../global/MediaMin";
import Media from "../global/Media";
import { getImageUrl } from "../Provider/Api";

const FeaturesWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 12%;
    ${Media.md} {
      padding: 0 ;
      }
    li {
      width: 100%;
      padding: 10px 0;
      border-top: solid 1px #000;

      ${MediaMin.md} {
        width: 45%;
        margin: 0  2.5% 0 2.5%;
      }
    }
  }
`;

const Features = ({ item }) => (
  <SectionMain
    bgImage={item.imgName ? getImageUrl(item.imgName) : ""}
    bgColor={item.backgroundColor}
  >
    <Container mdMW="70%">
      {item.viewHeading === "1" && <Heading2 className="center">{item.heading}</Heading2>}
      <FeaturesWrapper dangerouslySetInnerHTML={{ __html: item.content }} />
    </Container>
  </SectionMain>
);

export default Features;
