import { Link } from "react-router-dom";
import { FaTimes, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import styled from "styled-components";

import { useState } from "react";
import Media from "./Media";
import { ButtonClick } from "../global/BookNowButton";

const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(58, 66, 81, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  overflow-y: auto;
  z-index: 100;
  background: url('/nav-bg.jpeg');
  background-size: cover;

`;

const NavMenuLogo = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  max-width: 300px;
  ${Media.md} {
    max-width: 200px; 
  }
  z-index: 5;
  img {
    width: 100%;
  }
`;

const NavMenuTitle = styled(Link)`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: 300;
  
  &:hover {
    transition: 0.2s all ease-in-out;
    opacity: 0.7;
  }
`;

const StyledLink = styled.div`
  color: #fff;
  text-decoration: none;
  padding: 0.75rem 10.5rem;
  font-weight: 300;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: 22px;
  // &:hover {
  //   transition: 0.2s all ease-in-out;
  //   opacity: 0.7;
  // }

  ${Media.md} {
    padding: 0.5rem 1rem;
    font-size: 15px;
  }
`;

const CloseToggle = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.75rem;
  display: flex;
  place-items: center;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
`;
const SubMenuContent = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: url('/nav-bg.jpeg');
  background-size: cover;
  padding-top: 170px;
  top: 0;
  left: 0;
  padding-left: 100px;
`;
const SubMenuContentChildren = styled.div`
  padding-left: 30px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
`;
const MenuContent = styled.div`
  margin-top: 170px;
`;
const Subtitle = styled(Link)`
  margin: 10px 0;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    transition: 0.2s all ease-in-out;
    opacity: 0.7;
  }
`;

const BackButton = styled.button`
  margin-left: -30px;
  margin-bottom: 25px;
  font-size: 14px;
  border: none;
  background: transparent;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  ${Media.md} {
    margin-left: -10px;
    font-size: 12px;
  }
`;
const SubContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const MenuItemName = styled.div`
  display: inline;
  &:hover {
    opacity: 0.7;
    display: inline;
    margin-right: 20px;
  }
`;

// const Button = styled.a`
//   text-decoration: none;
//   color: #fff;
//   font-weight: 400;

//   letter-spacing: 2px;
//   display: flex;
   
//   &:hover {
//     background: #fff;
//     color: #000;
//   }
// `;

const ClickDiv = styled.div`
  cursor:pointer;
  display: flex;
  align-items: center;
`;
const NewDiv = styled.div`
  background: white;
  color: black;
  font-weight: bold;
  letter-spacing: 2px;
    padding: 5px;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: -5px;
    display: inline-block;
`;


const MenuItem = ({ item, handleNavToggle, show, setShow }) => {
  const [toggle, setToggle] = useState(false);
  const handleNavOpen = () => {
    setShow(false);
    setToggle(true);
  };

  const handleNavClose = () => {
    setShow(true);
    setToggle(false);
  };

  const mainlink = item.slugName;
  return (
    <>
      <ClickDiv onClick={handleNavOpen}>
        <MenuItemName>{item.name}</MenuItemName>
        {show && (
          <FaChevronRight
            className="chevronCls"
            style={{
              display: toggle ? "none" : "inline",
            }}
          />
        )}
      </ClickDiv>
      {toggle && item.children && (
        <SubMenuContent>
          <SubMenuContentChildren>
            <BackButton onClick={handleNavClose}>
              <FaChevronLeft style={{ fontSize: 12, marginRight: 10 }} />
              BACK
            </BackButton>
            <SubContent>
              <Subtitle to={"/" + item.slugName + "/"} onClick={handleNavToggle}>
                All {item.name}
              </Subtitle>
              {item.children &&
                item.children.map((item1, key) => (
                  <Subtitle
                    key={key}
                    to={"/" + mainlink + "/" + item1.slugName + "/"}
                    onClick={handleNavToggle}
                  >
                    {item1.name} {
                      item1.name === "The Lake House" && <NewDiv>New</NewDiv>
                    }
                  </Subtitle>
                ))}
            </SubContent>
          </SubMenuContentChildren>
        </SubMenuContent>
      )}
    </>
  );
};

const NavigationMenus = ({ menu, handleNavToggle }) => {
  const [show, setShow] = useState(true);
  return (
    <StyledMenu>
      <NavMenuLogo>
        <img src="https://d2057z2iq79qyw.cloudfront.net/uploads/24/page/logo2.png" alt="logo" />
      </NavMenuLogo>
      <MenuContent>
        {menu.map((item, index) => (
          <StyledLink key={index} className="animate__animated animate__fadeInRight">
            {item.children ? (
              <MenuItem
                key={index}
                item={item}
                handleNavToggle={handleNavToggle}
                show={show}
                setShow={setShow}
              />
            ) : (
              <NavMenuTitle
                key={index}
                onClick={handleNavToggle}
                to={item.slugName === "home" ? "/" : "/" + item.slugName + "/"}
              >
                {item.name}
              </NavMenuTitle>
            )}
          </StyledLink>
        ))}
         <StyledLink onClick={ButtonClick}>
            Book Now
          </StyledLink>
      </MenuContent>
      <CloseToggle
        className="animate__animated animate__fadeInRight"
        onClick={handleNavToggle}
      >
        <FaTimes />
      </CloseToggle>
    </StyledMenu>
  );
};

export default NavigationMenus;
