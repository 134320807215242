import { memo, useRef, useEffect } from "react";
import styled from "styled-components";
import { Container } from "./Container";
import ScrollAnimation from "react-animate-on-scroll";
import { getVideoBaseUrl } from "../Provider/Api";

const HomePageBannerWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  .custom-video1 {
    position: fixed;
    background-image: url("${(props) => props.background}");
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .custom-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    video {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
    iframe {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Heading = styled.h2``;

const Text = styled.p`
  max-width: 40ch;
  font-size: 1.125rem;
  line-height: 1.75;
  color: #fff;
  text-shadow: 0px 0px 8px #999;
`;

const BannerText = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: flex-end;
`;
/*
const HomeTileImage = styled.img`
  max-width: 400px;
  ${Media.md} {
    max-width: 250px;
  }
`;
*/

const HomeContent = styled.div`
  display: block;
  padding-top: 11rem;
`;

const HomePageBanner = ({ pageName, item }) => {
  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [item]);
  const EmbedVideo = (props) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
         <video
           loop
           muted
           autoplay
           playsinline
           src="${props.src}"
           class="${props.className}"
         />,
       `,
        }}
      ></div>
    );
  };
  return (
    <HomePageBannerWrapper>
      <div className="custom-video1"></div>
      <div className="custom-video">
        {/*<video loop={true} muted={true} autoplay={true} playsinline={true} ref={videoRef}>
          <source src={`/videos/${item.videoLink}.mp4`} type="video/mp4" />
        </video>
      */}
        <EmbedVideo src={`${getVideoBaseUrl()}${item.videoLink}.mp4`} className="video" />
      </div>
      {/*
      <iframe title="vimeo-player" src={`https://player.vimeo.com/video/${item.videoLink}?h=46a3beeb84&background=1`} frameborder="0" allowfullscreen></iframe>
      <img
        srcSet={`https://vumbnail.com/${item.videoLink}.jpg 640w, https://vumbnail.com/${item.videoLink}_large.jpg 640w, https://vumbnail.com/${item.videoLink}_medium.jpg 200w, https://vumbnail.com/${item.videoLink}_small.jpg 100w`}
        src={`https://vumbnail.com/${item.videoLink}.jpg`}
        sizes="(max-width: 640px) 100vw, 640px"
        alt="Vimeo Thumbnail"
        className="custom-video1"

      />
      <Vimeo
        className="custom-video"

        video={item.videoLink}
        autoplay
        background
        showTitle={false}
        loop
        showPortrait={true}
        controls={false}
        muted={true}

  />*/}
      <Container mdMW="60">
        <HomeContent>
          {/*pageName === "home" && (
            <Link to="/">
              <HomeTileImage
                className="homelogo"
                src="https://d2057z2iq79qyw.cloudfront.net/uploads/24/page/logo2.png"
                alt="Fiordland Lodge"
                style={{ width: "100%" }}
              />
            </Link>
          )*/}
          <BannerText>
            <div className="home-text">
              <ScrollAnimation animateIn="fadeInUp">
                {item.viewHeading === "1" && <Heading>{item.heading}</Heading>}
                <Text>{item.content}</Text>
              </ScrollAnimation>
              {/*<WatchLink>
              <FaRegPlayCircle />
              <span>Watch Full Video</span>
            </WatchLink>*/}
            </div>
          </BannerText>
        </HomeContent>
      </Container>
    </HomePageBannerWrapper>
  );
};

const HomePageBannerMemo = memo(HomePageBanner);
export { HomePageBannerMemo };
