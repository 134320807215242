import { memo } from "react";
import styled from "styled-components";
import MediaMin from "../global/MediaMin";
import Media from "../global/Media";
import {
  SectionMain,
  Heading2,
  Paragraph,
  ButtonLink,
  NotLinkButton,
} from "./Container";
import { getImageUrl, getVideoBaseUrl } from "../Provider/Api";
import { Link } from "react-router-dom";
import { ScrollTop } from "../global/BookNowButton";

const Row = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  ${MediaMin.md} {
    flex-direction: ${(props) =>
      props.index % 2 === 0 ? "row" : "row-reverse"};
  }
`;
const Col = styled.div`
  &.left {
    width: 100%;
    ${MediaMin.md} {
      width: 50%;
      padding: 40px;
    }
    padding: 0px;
  }
  &.right {
    width: 95%;
    ${MediaMin.md} {
      width: 50%;
      padding: 40px;
    }
    padding: 0px;
  }
  .custom-video1 {
    position: fixed;
    width: 100%;
    height: 300px;
    ${MediaMin.md} {
      width: 100%;
      height: 800px;
    }
    z-index: -2;
  }
  .custom-video {
    position: relative;
    width: 95%;
    height: 300px;
    ${MediaMin.md} {
      width: 100%;
      height: 800px;
    }
    video {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 350px;
      ${MediaMin.md} {
        min-height: 850px;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    overflow: hidden;
    iframe {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 350px;
      ${MediaMin.md} {
        min-height: 850px;
      }
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 350px;
        ${MediaMin.md} {
          min-height: 850px;
        }
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .imageLeft {
    transform: translate(-5vw, -140px);
    margin-left: auto;
    ${Media.md} {
      transform: translate(-55vw, -140px);
    }
  }

  .imageRight {
    transform: translate(5vw, -180px);
    ${Media.md} {
      transform: translate(55vw, -180px);
    }
  }

  .image {
    z-index: 2;
    position: relative;
    margin-bottom: -140px;
    width: 245px;
    padding-bottom: 300px;
    ${Media.md} {
      width: 150px;
      padding-bottom: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
`;

const Image = styled.div`
  width: 95%;
  height: 300px;
  background: url(${(prop) => prop.bgImage});
  background-size: cover;
  background-position: center center;
  ${MediaMin.md} {
    width: 100%;
    height: 600px;
  }
  margin-left: ${(props) => (props.index % 2 === 0 ? "" : "auto")}; ;
`;

const SectionTile = ({ item1, index1 }) => {
  const EmbedVideo = (props) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
         <video
           loop
           muted
           autoplay
           playsinline
           src="${props.src}"
           class="${props.className}"
         />,
       `,
        }}
      ></div>
    );
  };
  return (
    <SectionMain
      bgColor={item1.backgroundColor ? item1.backgroundColor : "#eeebe5"}
    >
      {item1.multiName ? (
        <>
          <Link to={item1.multiPageUrl} className="leftRightLink">
            <Row index={index1}>
              <Col className="left">
                {item1.multiAltImg ? (
                  <>
                    {item1.multiAltImg && (
                      <>
                        <div className="custom-video">
                          {/*<video muted autoPlay={true} loop>
                              <source src={`/videos/${item1.multiAltImg}.mp4`} type="video/mp4" />
                    </video>*/}
                          <EmbedVideo
                            src={`${getVideoBaseUrl()}${item1.multiAltImg}.mp4`}
                            className="video"
                          />
                        </div>
                      </>
                    )}
                    {/*
                    
                  
                    <Vimeo
                      className="custom-video"
                      style={{
                        display: showThumb && "none",
                        marginLeft: index1 % 2 !== 0 && "auto",
                      }}
                      video={item1.multiAltImg}
                      autoplay
                      background
                      showTitle={false}
                      loop
                      controls={false}
                      muted={true}
                      onPlay={() => setShowThumb(false)}
                    />
                    */}
                    {item1.multiImage && (
                      <div
                        className={`image ${
                          index1 % 2 === 0 ? "imageLeft" : "imageRight"
                        }`}
                      >
                        <img
                          src={getImageUrl(item1.multiImage)}
                          alt={item1.multiName}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <Image
                    bgImage={getImageUrl(item1.multiImage)}
                    index={index1}
                  />
                )}
              </Col>
              <Col className="right">
                <Heading2>{item1.multiName}</Heading2>
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: item1.multiDesc }}
                />

                <ButtonLink onClick={ScrollTop}>
                  {/* <Button to={item1.multiPageUrl}>Learn more</Button> */}
                  <NotLinkButton>Learn more</NotLinkButton>
                </ButtonLink>
              </Col>
            </Row>
          </Link>
        </>
      ) : (
        <>
          {item1.sectionDirection === "0" ? (
            <Link to={item1.new_url} className="leftRightLink">
              <Row index={index1}>
                <Col className="left">
                  <Heading2>{item1.heading}</Heading2>
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: item1.content }}
                  />

                  <ButtonLink onClick={ScrollTop}>
                    {/* <Button to={item1.multiPageUrl}>Learn more</Button> */}
                    <NotLinkButton>Learn more</NotLinkButton>
                  </ButtonLink>
                </Col>
                <Col
                  className="right"
                  transform="translate(5vw, -180px)"
                  marginLeft="0"
                >
                  {item1.videoLink ? (
                    <>
                      <div className="custom-video">
                        {/*<video muted autoPlay={true} loop>
                              <source src={`/videos/${item1.multiAltImg}.mp4`} type="video/mp4" />
                          </video>
                  */}
                        <EmbedVideo
                          src={`${getVideoBaseUrl()}${item1.multiAltImg}.mp4`}
                          className="video"
                        />
                      </div>

                      {item1.imgName && (
                        <div className="image imageRight">
                          <img
                            src={getImageUrl(item1.imgName)}
                            alt={item1.imgName}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <Image
                      bgImage={getImageUrl(item1.imgName)}
                      marginLeft="auto"
                    />
                  )}
                </Col>
              </Row>
            </Link>
          ) : (
            <Link to={item1.new_url} className="leftRightLink">
              <Row index={index1}>
                <Col
                  className="left"
                  transform="translate(-5vw, -140px)"
                  marginLeft="auto"
                >
                  {item1.videoLink ? (
                    <>
                      <div className="custom-video">
                        {/*<video muted autoPlay={true} loop>
                              <source src={`/videos/${item1.multiAltImg}.mp4`} type="video/mp4" />
                    </video>*/}
                        <EmbedVideo
                          src={`${getVideoBaseUrl()}${item1.videoLink}.mp4`}
                          className="video"
                        />
                      </div>

                      <div className="image imageLeft">
                        <img
                          src={getImageUrl(item1.imgName)}
                          alt={item1.imgName}
                        />
                      </div>
                    </>
                  ) : (
                    <Image bgImage={getImageUrl(item1.imgName)} />
                  )}
                </Col>
                <Col className="right">
                  <Heading2>{item1.heading}</Heading2>
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: item1.content }}
                  />

                  <ButtonLink onClick={ScrollTop}>
                    {/* <Button to={item1.multiPageUrl}>Learn more</Button> */}
                    <NotLinkButton>Learn more</NotLinkButton>
                  </ButtonLink>
                </Col>
              </Row>
            </Link>
          )}
        </>
      )}
    </SectionMain>
  );
};

const SectionTileMemo = memo(SectionTile);
export { SectionTileMemo };
