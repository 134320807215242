import Modal from "react-modal";
Modal.setAppElement("#root");

const Notice = ({ data, isOpen, toggleModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => toggleModal()}
      contentLabel="My dialog"
      className="mymodal text-center"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <div className="modelcontent">{data.content}</div>
      <hr className="modeldivider" />
      <button className="modelbutton" onClick={() => toggleModal()}>
        Close
      </button>
    </Modal>
  );
};
export default Notice;
