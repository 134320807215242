
const ButtonClick = () => {
  window.openWidget();
  //document.querySelector(".bookingwidget").classList.add("show");
}

const ScrollTop = () => {
  window.scrollTo(0,0);
}
export { ButtonClick, ScrollTop };
