import { SET_PAGE_DATA } from "./appActions";

export default function AppReducer(state, action) {
  switch (action.type) {
    case SET_PAGE_DATA:
      return {
        ...state,
        page_data: action.payload,
      };
    default:
      break;
  }
}
