import styled from "styled-components";
import Media from "../global/Media";


const CFooter = styled.footer`
  background: #2a3830;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 2rem;
  flex-wrap: wrap;
  ${Media.md} {
    padding-bottom: 4rem;
  }
`;

const SocialMedia = styled.div`
  background: #2a3830;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.05rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  ${Media.md} {
    margin-right: 1rem;
    margin-left: 1rem;
    flex-wrap: wrap;
  }
`;

const Span = styled.span`
  font-weight: 100;
  line-height: 1.5em;
  color: #fff;
  a {
    color: #fff;
  }
  margin: 10px 0;
  width: 100%;
  text-align: center;
  ${Media.md} {
    font-size: 0.75rem;
  }
`;
/*
const FooterMenuGroup = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  @media screen and (max-width: 479px) {
    margin-top: 10px;
  }
`;

const FooterMenu = styled.li`
  padding: 0 0.75rem;
  position: relative;
  ${MediaMin.md} {
    margin-bottom: 0;
  }
`;

const FooterMenuLink = styled(Link)`
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.5em;
  color: #fff;
  margin-right: 10px;

  letter-spacing: 0.06rem;
  text-decoration: none;
  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
`;
*/
const SocialIcon = styled.a`
  margin-left: 0.85rem;
  margin-right: 0.85rem;
  margin-top: 2rem;
  cursor:pointer;
`;

export default function Footer() {
  return (
    <CFooter>
      {/*<FooterTitle>
        Subscribe to stay tuned for news and latest updates. No junk, spam free.
      </FooterTitle>
      <FooterLink to="/subscribe">Subscribe</FooterLink>*/}
      <Copyright>
       {/* <FooterMenuGroup>
          <FooterMenu>
            <FooterMenuLink to="#">Privacy Policy</FooterMenuLink>
          </FooterMenu>

          <FooterMenu>
            <FooterMenuLink to="#">Terms &amp; Conditions</FooterMenuLink>
          </FooterMenu>
        </FooterMenuGroup>
    */}
        <Span>472 Te Anau Milford, Highway Postal: PO Box 196, Te Anau.</Span>
        <Span>
          Phone: <a href="tel://+6432497832">+64 3 249 7832</a>, Email:{" "}
          <a href="mailto:info@fiordlandlodge.co.nz">
            info@fiordlandlodge.co.nz
          </a>
        </Span>

        <Span>
          © Fiordland Lodge Te Anau | Website by <a rel="noreferrer" target="_blank" href="https://www.skyhi.co.nz">SkyHi Agency</a>
        </Span>
      </Copyright>
      <SocialMedia>
        <SocialIcon target="_blank"  href="https://www.facebook.com/pages/Fiordland-Lodge/110815622307487?v=wall&ref=ts">
          <img
            src="/images/32-4.svg"
            height="24"
            width="24"
            alt=""
          />
        </SocialIcon>
        <SocialIcon target="_blank" href="https://www.instagram.com/fiordlandlodge/">
          <img
            src="/images/32-3.svg"
            height="18"
            width="22"
            alt=""
          />
        </SocialIcon>
        <SocialIcon target="_blank" href="https://www.tripadvisor.in/Hotel_Review-g255124-d318393-Reviews-Fiordland_Lodge-Te_Anau_Fiordland_National_Park_Southland_Region_South_Island.html">
          <img
            src="/images/24-1.svg"
            height="18"
            width="22"
            alt=""
          />
        </SocialIcon>
      </SocialMedia>
    </CFooter>
  );
}
