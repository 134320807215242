import styled from "styled-components";
import Media from "../global/Media";
import MediaMin from "../global/MediaMin";
import { Link } from "react-router-dom";

const ContainerElement = styled.div`
  max-width: 86%;
  ${MediaMin.md} {
    max-width: ${(prop) => prop.mdMW + "%" || "10%"};
  }
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`;

export const SectionMain = styled.section`
  background-size: cover;
  background-color: ${(prop) => prop.bgColor || ""};
  background-image: url(${(prop) => prop.bgImage || ""});
  background-repeat: no-repeat;
  padding: 2em 0;
  width: 100%;
  height: ${(prop) => prop.height || "auto"};
  .leftRightLink {
    all: unset;
    cursor: pointer;
  }
  ${MediaMin.md} {
    padding: 4em 0;
  }
  position: relative;
`;

export const Heading2 = styled.h2`
  margin: 0 0 20px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75;
  font-style: normal;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  &.center {
    text-align: center;
  }
  
  color: ${(prop) => prop.color || "#000"};
  ${MediaMin.md} {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.75;
    font-style: normal;
  }
  ${Media.md} {
    margin-left: 20px;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7em;
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${(prop) => prop.color || "#000"};
  ${MediaMin.md} {
    font-size: 1.125rem;
    font-style: normal;
    margin-left: 68px;
  }
`;

export const ButtonLink = styled.div`
  margin: 0 0 20px;
  ${MediaMin.md} {
    margin-left: 68px;
  }
  ${Media.md} {
    margin-left: 20px;
  }
`;

export const Button = styled(Link)`
  padding-bottom: 10px;
  text-decoration: none;
  border-bottom: solid 1px #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7em;
`;

export const NotLinkButton = styled.button`
  all: unset;
  padding-bottom: 10px;
  text-decoration: none;
  border-bottom: solid 1px #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7em;
`;

export const Container = ({ mdMW, children }) => {
  return <ContainerElement mdMW={mdMW}>{children}</ContainerElement>;
};
