import styled from "styled-components";
import Media from "./Media";
import MediaMin from "./MediaMin";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ButtonClick } from "./BookNowButton";
import { useEffect } from "react";

const NavHeader = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 51;
  width: 100%;
  display: flex;
  padding: 10px 0;
  transition: all 0.5s ease-in;
  &.scrolled {
    background: rgba(0,0,0,0.8);
    .logo {
      img {
        max-width: 150px;
      }
    }
  }
  .link {
    cursor: pointer;
    padding: 0.8rem 1rem;
    text-decoration: none;
    
    background: #fff;
      color: #000;
      font-weight: 400;
    font-size: 0.8rem;
    margin-right: 20px;
    letter-spacing: 2px;
    border: solid 1px #eee;
    text-shadow: 0px 0px 8px #999;
    &:hover {
      background: #fff;
      color: #000;
    }
    display: none;
    ${MediaMin.md} {
      display: block;
    }
  }
  .logo {
    width: 50%;
    
    ${MediaMin.md} {
      width: 30%;
    }

    img {
      width: 100%;
      max-width: 300px;
    }
    a {
      display: block;
    }
  }
  .menu {
    width: 50%;
    ${MediaMin.md} {
      width: 70%;
    }
    display: flex;
    margin-top: 5px;
    justify-content: flex-end;
    align-items: center;
  }
`;
const Button = styled.a`
  cursor: pointer;
  padding: 0.8rem 1rem;
  text-decoration: none;
  border: solid 1px #2a3830;
  background: #2a3830;
    color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  margin-right: 20px;
  letter-spacing: 2px;
  
  text-shadow: 0px 0px 8px #999;
  &:hover {
    background: #fff;
    color: #000;
  }
  display: none;
  ${MediaMin.md} {
    display: block;
  }
`;

const MenuTitle = styled.span`
  margin-right: 0.5rem;
  display: none;
  ${MediaMin.md} {
    display: block;
  }
`;

const StyledToggle = styled.button`
  color: #000;
  background: #fff;
  border: solid 1px #eee;
  padding: 0.8rem 1rem;
  display: flex;
  place-items: center;

  font-size: 0.8rem;
  margin-right: 10px;
  letter-spacing: 0.16667em;
  cursor: pointer;
  text-transform: uppercase;

  ${Media.md} {
    z-index: 2;
  }
`;


const Header = ({ logo, handleNavToggle }) => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const nav = document.querySelector("#nav");
      if (window.scrollY > 100) {
        nav.classList.add("scrolled");
      } else {
        nav.classList.remove("scrolled");
      }
    });
  }, []);
  return (
    <NavHeader id="nav">
      <div className="logo">
        {logo && (
          <Link to="/">
            <img src="https://d2057z2iq79qyw.cloudfront.net/uploads/24/page/logo2.png" alt="logo" />
          </Link>
        )}
      </div>
      <div className="menu">
        <Button onClick={ButtonClick}>
          BOOK NOW
        </Button>
       {/*   
        <Link className="link" to="/accommodation/the-lake-house/">
          THE LAKE HOUSE
        </Link>
        */}

        <StyledToggle
          className="animate__animated animate__fadeInRight"
          onClick={handleNavToggle}
        >
          <MenuTitle>Menu</MenuTitle> <FaBars />
        </StyledToggle>
      </div>
    </NavHeader>
  )
};

export default Header;
