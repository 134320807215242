import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import WebFont from "webfontloader";
import Home from "./components/Pages/Home";
import Error from "./components/Error/Error";

import Pages from "./components/Pages/Pages";
import { createGlobalStyle } from "styled-components";
import theme from "./components/global/Theme";
import { GlobalProvider } from "./context/globalState";
import { getMenu, getAllPageData } from "./components/Provider/Api";
//import  pageData  from "./api/api.json";
//import menu from "./api/menu.json";

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  // font-family: Roboto;
  font-family: 'Roboto', sans-serif;
  color: ${theme.BodyColor};
}
h1, h2, h3, h4, h5, h6 {
  color: ${theme.Heading};
}
p {
  color: ${theme.BodyColor};
}
a,  button {
  color: ${theme.Button};
}
iframe {
  z-index: 0 !important;
}
`;

const App = () => {
  const [menu, setMenu] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto"],
      },
    });
    getMenu().then((res) => setMenu(res.data));
    getAllPageData('/api.json').then((res) => setPageData(res.data)); 
  }, []);

  //console.log("pagedasta", pageData, menu);
  return (
    <GlobalProvider>
      {/** Route page starts here */}
      <GlobalStyle />
      
      {pageData && menu && 
      <Router>
        <Routes>
          <Route key="0" exact path="/" element={<Home menu={menu} pageData={pageData} />} />
          
          {menu.map((item, index) => (
            <>
              {item.slugName !== "home" && item.slugName !== "the-lake-house" && (
                <>
                  <Route
                    exact
                    key={index}
                    path={"/" + item.slugName + "/"}
                    element={
                      <Pages menu={menu} item={item} apiurl={item.slugName}  pageData={pageData} />
                    }
                  />
                  {item.children &&
                    item.children.length > 0 &&
                    item.children.map((item1, index1) => (
                      <Route
                        exact
                        key={index1}
                        path={"/" + item.slugName + "/" + item1.slugName + "/"}
                        element={
                          <Pages
                            menu={menu}
                            item={item1}
                            apiurl={item1.slugName}
                            pageData={pageData}
                          />
                        }
                      />
                    ))}
                </>
              )}
            </>
          ))}

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    }
      {/**Route page end here  */}

      <input type="hidden" id="STAAHMaxWidgetID" value="jmzrIMRm0HZNACTeJf6BbfkS/yZIZjZnCjq8YzQWL7k="></input>
    </GlobalProvider>
  );
};

export default App;
