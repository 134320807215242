import { useState } from "react";
import styled from "styled-components";
import Header from "../global/Header";
import NavigationMenus from "../global/NavigationMenus";
import Footer from "../global/Footer";
import "../../css/animate.css";
import { Link } from "react-router-dom";
import LoadSection from "../sections/LoadSection";
import Meta from "../global/Meta";
import { Loading } from "../global/Svg";
import Notice from "../global/Notice";
import MediaMin from "../global/MediaMin";
import { ButtonClick, ScrollTop } from "../global/BookNowButton";
import { Container } from "../sections/Container";

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: white;

  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Col = styled.div`
  &.left {
    width: 100%;
    padding: 20px 10px;
    background-color: #2a3830;
    border-right-width: 1px;
    border-right-color: #fff;
  }
  &.right {
    width: 90%;
    padding: 20px 10px;
    background-color: #2a3830;
  }
  text-align: center;
`;
const Button = styled.a`
  text-decoration: none;
  color: #fff;
  font-weight: 400;

  letter-spacing: 2px;
  display: flex;
    align-items: center;
    justify-content: center;
  &:hover {
    background: #fff;
    color: #000;
  }
`;
const Row = styled.div`
  display: flex;
  margin: 0;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1009;
  ${MediaMin.md} {
    display: none;
  }
`;

const BreadCrumbWrapper =  styled.section`
  background: #d3d3d3;
  width: 100%;
  display: flex;
  padding: 20px;
  a {
    text-decoration: none;
    border-bottom: solid 1px #999;
  }
`;
const Pages = ({ menu, item, apiurl, pageData }) => {


  const [navToggled, setNavToggled] = useState(false);
  //const [content, setContent] = useState({ loading: true, result: {} });
  const content = {
    loading: false,
    result: pageData[apiurl],
  };
  const [isOpen, setIsOpen] = useState(false);

 /* useEffect(() => {
    if (
      content.result &&
      content.result.data &&
      content.result.data.noticeArr &&
      content.result.data.noticeArr.length > 0 > 0
    ) {
      setIsOpen(true);
    }
  }, [content]);
*/
  const toggleModal = () => {
    setIsOpen(false);
    const elements = document.getElementsByClassName("ReactModalPortal");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  const handleNavToggle = () => {
    setNavToggled(!navToggled);
    ScrollTop();
  };

  let newlink = "/";
  return (
    <>
      {content.loading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {content.result && content.result.data && (
        <Meta
          metaTitle={content.result.data.metaTitle}
          metaDesc={content.result.data.metaDesc}
          metaKeywords={content.result.data.metaKeywords}
        />
      )}
      {navToggled ? (
        <NavigationMenus menu={menu} handleNavToggle={handleNavToggle} />
      ) : null}
      <Header logo={true} handleNavToggle={handleNavToggle} />
      {content.result &&
        content.result.data &&
        content.result.data.noticeArr &&
        content.result.data.noticeArr.length > 0 && (
          <Notice
            data={content.result.data.noticeArr[0]}
            isOpen={isOpen}
            toggleModal={toggleModal}
          />
        )}
      {content.result &&
        content.result.sections &&
        content.result.sections.map((item, index) => (
          <>
            {index === 1 && 
              <BreadCrumbWrapper>
               <Container className="container">
                <Link to="/" onClick={ScrollTop}>Home</Link> <span> / </span>
                {
                  content.result.breadcrumb.map((item1, index1) => {
                     newlink += `${item1.slugName}/`;
                    return (
                      <>
                        
                        {index1 < content.result.breadcrumb.length - 1 ? <> <Link  onClick={ScrollTop} key={index1} to={newlink}>{item1.pageName}</Link> <span> / </span> </> : 
                        item1.pageName
                        }
                      </>
                    )
                    
                  })
                }  
              </Container>
              </BreadCrumbWrapper>
            }
            <LoadSection
              key={index}
              page={content.result.data.pageName}
              item={item}
            />
          </>
        ))}

      {content.result && 
      <Footer />
      }
      {!navToggled && (
        <Row>
          <Col className="left">
          <Button onClick={ButtonClick}>
            BOOK NOW
          </Button>
          </Col>
          <Col className="right">
            <Button  onClick={ScrollTop} href="/accommodation/the-lake-house/">
            THE LAKE HOUSE
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Pages;
